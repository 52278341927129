const hideFirebaseEmulatorWarning = () => {
	try {
		const nodeEnv = process.env.NODE_ENV;
		if (nodeEnv !== 'development' && nodeEnv !== 'test') return;

		// Code to hide firebase emulator warning since it blocks UI elements
		const firebaseEmulatorWarning = document.getElementsByClassName(
			'firebase-emulator-warning'
		);
		if (firebaseEmulatorWarning.length > 0) {
			setTimeout(() => {
				firebaseEmulatorWarning[0].style.display = 'none';
			}, 500);
		}
	} catch (err) {
		console.error('Error hiding firebase emulator warning:', err);
	}
};

export { hideFirebaseEmulatorWarning };
