import React from 'react';
import { CALL_STATUS } from '../hooks/useVapi';
import { Loader2, Mic, Square } from 'lucide-react';

const RondahButton = ({ toggleCall, callStatus, audioLevel = 0 }) => {
	const color =
		callStatus === CALL_STATUS.ACTIVE
			? 'red'
			: callStatus === CALL_STATUS.LOADING
			? 'orange'
			: 'teal-500';
	const buttonStyle = {
		borderRadius: '50%',
		width: '50px',
		height: '50px',
		color: 'white',
		border: 'none',
		boxShadow:
			callStatus === CALL_STATUS.ACTIVE
				? `1px 1px ${10 + audioLevel * 40}px ${audioLevel * 10}px ${color}`
				: 'none',
		cursor: 'pointer',
	};

	const handleClick = () => {
		if (callStatus !== CALL_STATUS.LOADING) {
			toggleCall();
		}
	};

	return (
		<button
			style={buttonStyle}
			className={`transition ease-in-out ${
				callStatus === CALL_STATUS.ACTIVE
					? 'bg-red-500 hover:bg-red-700'
					: callStatus === CALL_STATUS.LOADING
					? 'bg-orange-500 hover:bg-orange-700'
					: 'bg-teal-500 hover:bg-teal-700'
			} flex items-center justify-center`}
			// onClick={toggleCall}
			onClick={handleClick}
			disabled={callStatus === CALL_STATUS.LOADING}
		>
			{callStatus === CALL_STATUS.ACTIVE ? (
				<Square />
			) : callStatus === CALL_STATUS.LOADING ? (
				<Loader2 className='animate-spin' />
			) : (
				<Mic />
			)}
		</button>
	);
};

export default RondahButton;
