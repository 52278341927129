import { useCallback, useState } from 'react';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';

const useHandleUserFormSubmit = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const handleUserFormSubmit = useCallback(async (formData) => {
		setLoading(true);
		setError(null);
		try {
			// AuthProvider should automatically perform anonymous sign-in, so this if block should never be true
			if (!auth.currentUser || !auth.currentUser.uid) {
				throw new Error('Unauthenticated user form submission.');
			}

			const sanitizedData = preprocessFormData(formData);
			const uid = auth.currentUser.uid;
			const ref = doc(db, 'demoUserFormSubmissions', uid);
			let docSnap = await getDoc(ref);
			if (docSnap.exists()) {
				await setDoc(
					ref,
					{
						...sanitizedData,
						updatedAt: serverTimestamp(),
					},
					{ merge: true }
				);
			} else {
				await setDoc(ref, {
					...sanitizedData,
					createdAt: serverTimestamp(),
				});
			}
			return true;
		} catch (err) {
			console.error('handleUserFormSubmit error:', err);
			setError(err);
			return false;
		} finally {
			setLoading(false);
		}
	}, []);

	return {
		error,
		loading,
		handleUserFormSubmit,
	};
};

// Helpers
const preprocessFormData = (data) => {
	return {
		name: sanitizeInput(data.name),
		email: sanitizeInput(data.email).toLowerCase(), // Convert email to lowercase
		phone: sanitizeInput(data.phone),
	};
};

const sanitizeInput = (input) => {
	// Trim whitespace and remove script tags or any other potentially harmful characters
	return input.replace(/<script.*?>.*?<\/script>/gi, '').trim();
};

export { useHandleUserFormSubmit };
