import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';

const FunctionNamesEnum = {
	REGISTER_RETELL_WEB_CALL: process.env.REACT_APP_REGISTER_RETELL_WEB_CALL_FN_NAME ?? '',
};

export const registerRetellWebCall = httpsCallable(
	functions,
	FunctionNamesEnum.REGISTER_RETELL_WEB_CALL
);
