import React from 'react';
import { InlineWidget, PopupModal } from 'react-calendly';
import { widgetUrl } from '../config/calendly';

const CalendlyWidget = (props) => {
	const {
		initialText = 'Contact Sales',
		open,
		onModalClose,
		type = 'popup',
	} = props;

	const pageSettings = {
		backgroundColor: '2F2F2F',
		hideEventTypeDetails: false,
		primaryColor: '6fcac5',
		textColor: 'ffffff',
	};

	if (type === 'inline') {
		return <InlineWidget url={widgetUrl} pageSettings={pageSettings} />;
	}

	return (
		<PopupModal
			open={open}
			onModalClose={onModalClose}
			url={widgetUrl}
			rootElement={document.getElementById('root')}
			pageSettings={pageSettings}
			text={initialText}
			textColor='#ffffff'
			color='#6fcac5'
		/>
	);
};

export default CalendlyWidget;
