import React, { useEffect, useState } from 'react';
import { Loader2 } from 'lucide-react';
import { CalendlyWidget, MaintenanceNotice, RondahButton, UserForm } from './components';
import { AuthProvider, useAuth } from './hooks/useAuth';
import { RetellProvider, useRetell } from './hooks/useRetell';
import { VapiProvider, useVapi } from './hooks/useVapi';
import './assets/fonts/fonts.css';
import './index.css';
import './App.css';

function App() {
	const { handleUser, loading, user } = useAuth();
	const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
	const [userFormSubmitted, setUserFormSubmitted] = useState(false);
	// const { audioLevel, callStatus, toggleCall } = useVapi();
	const { audioLevel, callStatus, toggleCall } = useRetell();

	const openCalendly = () => {
		setIsCalendlyOpen(true);
	};

	const closeCalendly = () => {
		setIsCalendlyOpen(false);
	};

	const handleLearnMore = () => {
		window.open('https://www.rondah.ai', '_blank');
	};

	const handleContactSales = () => {
		openCalendly();
	};

	const startDemo = ({ instantCall = false }) => {
		setUserFormSubmitted(true);
		handleUser(user);
		// if (instantCall) {
		// 	toggleCall();
		// }
	};

	useEffect(() => {
		const setupCompleted =
			user && user.email && user.name && user.phone && user.uid;
		if (!loading && !userFormSubmitted && setupCompleted) {
			startDemo({ instantCall: false });
		}
	}, [userFormSubmitted, loading, user]);

	if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
		return <MaintenanceNotice />;
	}

	const Loader = () => {
		return <Loader2 className='animate-spin h-50 w-50' />;
	};

	const Demo = () => {
		if (!userFormSubmitted) {
			return <UserForm onSubmit={startDemo} />;
		}

		return (
			<>
				<div className='talk-to-rondah-container'>
					<h1>Talk to Rondah</h1>
					<RondahButton
						audioLevel={audioLevel}
						callStatus={callStatus}
						toggleCall={toggleCall}
					/>
					<div className='flex justify-center space-x-4 mt-4'>
						<button className='action-button' onClick={handleLearnMore}>
							Learn More
						</button>
						<button className='action-button' onClick={handleContactSales}>
							Contact Sales
						</button>
					</div>
				</div>
				<CalendlyWidget open={isCalendlyOpen} onModalClose={closeCalendly} />
			</>
		);
	};

	return <div className='App'>{loading ? <Loader /> : <Demo />}</div>;
}

export default () => {
	return (
		<AuthProvider>
			<RetellProvider>
				<VapiProvider>
					<App />
				</VapiProvider>
			</RetellProvider>
		</AuthProvider>
	);
};
