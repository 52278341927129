import React, { useState } from 'react';
import { Loader2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useHandleUserFormSubmit } from '../lib/user';

const UserForm = (props) => {
	const { onSubmit } = props;
	const [submitError, setSubmitError] = useState('');
	const {
		formState: { errors, isValid },
		handleSubmit,
		register,
		reset,
	} = useForm({
		mode: 'onChange', // Validate the form at every change}
	});
	const { handleUserFormSubmit, loading } = useHandleUserFormSubmit();

	const onSubmitData = async (data) => {
		try {
			const success = await handleUserFormSubmit(data);
			if (!success) {
				throw new Error('User form submission handler failed.');
			}

			onSubmit({
				instantCall: true,
			}); // Trigger parent submit handler
			reset(); // Reset form fields
			setSubmitError('');
		} catch (err) {
			console.error('Error submitting form data:', err);
			setSubmitError('Sorry something went wrong. Please try again later.');
		}
	};

	return (
		<form className='form' onSubmit={handleSubmit(onSubmitData)}>
			<h1>RONDAH AI DEMO</h1>
			<input
				{...register('name', {
					required: true,
					pattern: {
						value: /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/,
						message: 'First and last name required',
					},
				})}
				placeholder='Full name'
				type='text'
			/>
			{errors.name && (
				<p className='errorMessage'>First and last name is required</p>
			)}
			<input
				{...register('email', {
					required: true,
					pattern: {
						value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
						message: 'Invalid email',
					},
				})}
				placeholder='Email'
				type='email'
			/>
			{errors.email && (
				<p className='errorMessage'>Please enter a valid email</p>
			)}
			<input
				{...register('phone', {
					required: true,
					pattern: {
						value: /^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/,
						message: 'Invalid phone number',
					},
				})}
				placeholder='Phone'
				type='tel'
			/>
			{errors.phone && (
				<p className='errorMessage'>Please enter a valid phone number</p>
			)}
			<button
				type='submit'
				disabled={!isValid || loading}
				className='inline-flex justify-center items-center'
			>
				{loading ? <Loader2 className='animate-spin' /> : 'Talk to Rondah'}
			</button>
			{submitError && <p className='errorMessage'>{submitError}</p>}{' '}
		</form>
	);
};

export default UserForm;
