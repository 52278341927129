import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getPerformance } from 'firebase/performance';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { hideFirebaseEmulatorWarning } from '../lib/utils';

let app;
let analytics;
let auth;
let db;
let functions;
let perf;
let storage;

const connectProd = () => {
	analytics = getAnalytics(app);
	auth = getAuth(app);
	db = getFirestore(app);
	functions = getFunctions(app);
	perf = getPerformance(app);
	// storage = getStorage(app);
};

const connectEmulators = () => {
	auth = getAuth();
	connectAuthEmulator(auth, 'http://localhost:9099');

	db = getFirestore();
	connectFirestoreEmulator(db, 'localhost', 8080);

	functions = getFunctions();
	connectFunctionsEmulator(functions, 'localhost', 5001);

	// storage = getStorage();
	// connectStorageEmulator(storage, 'localhost', 9199);

	hideFirebaseEmulatorWarning();
};

try {
	const nodeEnv = process.env.NODE_ENV;
	const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
	app = initializeApp(firebaseConfig);

	switch (nodeEnv) {
		case 'development': {
			connectEmulators();
			break;
		}

		case 'test': {
			connectEmulators();
			break;
		}

		case 'production': {
			connectProd();
			break;
		}

		default:
			throw new Error(`Invalid NODE_ENV: ${nodeEnv}`);
	}
} catch (err) {
	console.error('Firebase initialization failed:', err);
	// TODO: Log to server/cloud
} finally {
	// TODO: Maybe log something here for analytics
}

export { analytics, auth, db, functions, storage };
