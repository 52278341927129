import moment from 'moment-timezone';
import defaultTemplate from './template.json';

const defaultVariablesMap = {
	current_datetime: moment()
		.tz('America/Los_Angeles')
		.format('h:mm A, MMMM Do YYYY'),
};

const generateAssistantConfig = (
	template = defaultTemplate,
	variablesMap = defaultVariablesMap
) => {
	try {
		let configString = JSON.stringify(template);

		Object.keys(variablesMap).forEach((key) => {
			const placeholder = new RegExp(`\\{\\{${key}\\}\\}`, 'g');
			configString = configString.replace(placeholder, variablesMap[key]);
		});

		const config = JSON.parse(configString);
        console.log('generateAssistantConfig', config);
		return config;
	} catch (err) {
		// TODO: Add logging
		throw err;
	}
};

export { generateAssistantConfig };
