import React, { useState, useEffect, useContext, createContext } from 'react';
import { signInAnonymously, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';

const AuthContext = createContext();

export function AuthProvider({ children }) {
	const auth = useProvideAuth();
	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
	return useContext(AuthContext);
};

function useProvideAuth() {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const handleUser = async (rawUser) => {
		try {
			if (rawUser) {
				const user = formatUser(rawUser);

				const uid = rawUser.uid;
				const ref = doc(db, 'demoUserFormSubmissions', uid);
				let docSnap = await getDoc(ref);
				if (docSnap.exists()) {
					const { email, name, phone } = docSnap.data();
					user.email = email || user.email;
					user.name = name || user.name;
					user.phone = phone || user.phone;
				}

				setUser(user);
				return user;
			} else {
				setUser(false);
				return false;
			}
		} catch (err) {
			console.error('handleUser error:', err);
			setError(err);
		} finally {
			setLoading(false);
		}
	};

	const signinAnonymously = () => {
		setLoading(true);
		setError(null);
		return signInAnonymously(auth)
			.then((response) => handleUser(response.user))
			.catch((err) => setError(err));
	};

	const signout = () => {
		setError(null);
		return signOut(auth)
			.then(() => handleUser(false))
			.catch((err) => setError(err));
	};

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user) {
				await handleUser(user);
			} else {
				// Sign in anonymously if the user is not authenticated
				signinAnonymously();
			}
		});

		return () => unsubscribe();
	}, []);

	return {
		user,
		loading,
		error,
		handleUser,
		signinAnonymously,
		signout,
	};
}

// Helpers
const formatUser = (user) => {
	return {
		uid: user?.uid,
		email: user?.email,
		name: user?.displayName,
		isAnonymous: user?.isAnonymous,
		phone: user?.phoneNumber,
		providerId: user?.providerId,
	};
};
